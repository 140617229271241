<template lang="pug">
.vc-shared-common-k-card-kol-card-v1(
  :class="[options?.imageClickable ? 'image-clickable' : '']"
)
  .image-wrapper(@click="imageClickHandler")
    slot(name="image-wrapper")
      img.image(:src="DefaultKolnetImage")
    .overlay
    .overlay-image-wrapper
      slot(name="overlay-image-wrapper")
        img.image(:src="DefaultKolnetImage")
    .image-info-wrapper
      slot(name="image-info-wrapper")
  .info-wrapper
    slot(name="info-wrapper")
      //- Below is example of info
      //- .item
      //-   .item-image-wrapper
      //-     img.image(:src="DefaultKolnetImage")
      //-   .item-info
      //-     .title Item Title
      //-     .note Item Note
  .footer-wrapper(v-if="$slots['footer-wrapper']")
    slot(name="footer-wrapper")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import DefaultKolnetImage from '@kolcenter/images/default-kolnet.png'

interface Options {
  imageClickable?: Boolean
}

const props = defineProps<{
  options?: Options
}>()

const emit = defineEmits(['image-click'])

function imageClickHandler() {
  if (!props.options?.imageClickable) return

  emit('image-click')
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-shared-common-k-card-kol-card-v1
  $gap: 1rem
  $cover-height: 360px

  transition: all .3s
  height: 100%
  border-radius: 20px
  overflow: hidden
  box-shadow: 0px 0px 1rem rgba(color.$black, 0.1)
  display: grid
  grid-template-rows: auto 1fr auro
  background-color: var(--card-background-color, color.$white)
  &:hover
    box-shadow: 0 0 1rem rgba(color.$black, .25)
    transform: translateY(-2px)

  &.image-clickable
    > .image-wrapper
      cursor: pointer

  > .image-wrapper
    grid-row: 1
    background-size: cover
    position: relative
    aspect-ratio: 9 / 10

    > .overlay
      background-color: rgba(color.$black, 0.5)
      backdrop-filter: blur(0)
      inset: 0
      position: absolute
      transition: all .3s

    > .overlay-image-wrapper
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      color: color.$white
      display: flex
      flex-direction: column
      align-items: center

    > .image-info-wrapper
        position: absolute
        bottom: 15px
        left: 50%
        transform: translate(-50%, 0)
        border-radius: 10px
        background-color: hsla(0, 0%, 4%, 0.36)
        width: max-content
        z-index: 1
        padding: .25rem .5rem
        display: flex
        align-items: center
        border-radius: 1rem
        line-height: 1
        font-size: 14px
        > *
          flex-shrink: 0
          opacity: 1
          color: color.$white

  .info-wrapper
    grid-row: 2
    padding: size.$gap * 0.5
    display: flex
    flex-direction: column
    gap: .5rem
    .item
      display: flex
      gap: .5rem
      > .item-image-wrapper
        max-width: 20%
      > .item-info
        display: flex
        flex-direction: column
        justify-content: space-between
      > .item-info >.title
        font-size: 14px
        font-weight: font.$bold
        margin-bottom: 0
      > .item-info >.note
        font-size: font.$size-7
        color: color.$pink

  .footer-wrapper
    grid-row: 3
    padding: size.$gap * 0.5
    display: flex
    gap: .5rem
</style>
